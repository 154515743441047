import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const KozatskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "kozatski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Козацькі пісні"
        description="Козацькі пісні – вид українських фольклорних творів, що виникли й поширилися у 16–17 ст. в епоху активної діяльності українських козаків (XVII-XVIII ст.)."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Козацькі пісні
          </h1>
          <p className="typo-body mt-4 max-w-2xl">
            Козацькі пісні – вид українських фольклорних творів, що виникли й поширилися у 16–17 ст. в епоху активної діяльності українських козаків (XVII-XVIII ст.).
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список козацьких пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру козацької пісні
        </h2>
        <p className="typo-body mt-4">
          Козацькі пісні пов’язані з історією народу; найдавніші з них оспівують героїзм захисників батьківщини від
          турецько-татарських загарбників, готовність молодих патріотів пожертвувати родинним затишком, а може, й
          життям, — тому в цих піснях важливе місце займає мотив прощання з родиною, битви з ворогом і героїчної
          загибелі козака. “Моя прекрасна, могутня, волелюбива Україна важко починала своїм вільним і ворожим трупом
          незліченні величезні кургани, — з романтичною піднесеністю і захопленям писав Шевченко, — вона своєї слави на
          поталу не давала, ворога-деспота під ноги топтала і — вільна, нерозтлінна — вмирала. Недаремно сумні ваші
          пісні, задумливі земляки мої. Їх склала свобода, а співала тяжка самотня неволя”.
        </p>
        <p className="typo-body mt-4">
          Класові суперечності, які все більше загострювалися з часом теж знайшли своє відображення в козацьких піснях:
        </p>
        <p className="typo-body italic mt-4">
          Ой, як тяжко жити стало,
          <br />
          Бо ті прокляті пани
          <br />
          Із нас шкури поздирали
          <br />
          І пошили жупани…
        </p>
        <p className="typo-body mt-4">
          В козацьких піснях підкреслена непокірність молоді, що не хотіла закріпачення і обирала життя, повне тривог і небезпек, але вільне, тому й покидала рідні місця “не з добра, не з розкоші, а з великого лиха”.
        </p>
        <p className="typo-body mt-4">
          В піснях цього циклу часто йдеться не про вчинки, а про емоції героя, — це, переважно, тужливий настрій самотнього, безталанного козака, безрідного сироти, якому “степ широкий — рідний брат”, а “шабля й люлька — вся родина”.
        </p>
        <p className="typo-body mt-4">
          Єдиний вірний товариш, що оплакує зраненого помираючого козака — його бойовий кінь:
        </p>
        <p className="typo-body italic mt-4">
          …що в головах ворон кряче,
          <br />
          а в ніженьках коник плаче…
        </p>
        <p className="typo-body mt-4">
          Кінь сповіщає рідню про героїчну загибель козака:
        </p>
        <p className="typo-body italic mt-4">
          …не плач, мати, не журися,
          <br />
          та вже твій син оженився,
          <br />
          та взяв собі паняночку,
          <br />
          в чистім полі земляночку…
        </p>
        <p className="typo-body mt-4">
          Кінь йде й за труною свого господаря:
        </p>
        <p className="typo-body italic mt-4">
          …козака несуть і коня ведуть,
          <br />
          кінь головоньку клонить…
        </p>
        <p className="typo-body mt-4">
          Козацькі пісні відзначаються багатою палітрою художніх засобів, особливою мелодійністю, яка досягається зокрема і тонко підібраними асонансами і алітераціями. Наприклад, у пісні “Козаченьку, куди йдеш?” настрій суму підкреслено повторами слів, у яких є звуки “іс”, “ес”, “ос”:
        </p>
        <p className="typo-body italic mt-4">
          Ждала дівка навісна,
          <br />
          мина осінь ще й весна.
        </p>
        <p className="typo-body mt-4">
          Улюблена метафора козацьких пісень — це зображення смерті як одруження або як непробудного сну: “заснув в степу він, сердега, довіку”; битви — як засіву чи кривавого банкету. Окремі образи, символи, метафори й мовні кліше з поетичного арсеналу козацьких пісень зустрічаємо в інших циклах та жанрах, бо вони, виявляючи народний ідеал, якнайкраще відповідають його естетичним смакам та уподобанням.
        </p>
      </div>
    </>
  );
};

export default KozatskiPisniPage;
